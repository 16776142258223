import { CognitoIdentityProvider, CognitoIdentityProviderClient } from '@aws-sdk/client-cognito-identity-provider';
export const IS_PRD = process.env.NODE_ENV === 'production';
export const IS_DEV = process.env.NODE_ENV === 'development';
export const IS_STG = (process.env.NODE_ENV as any) === 'staging';
export const REACT_APP_API_BASE_URL = IS_DEV
  ? `http://localhost:${process.env.REACT_APP_SERVER_APP_PORT}`
  : process.env.REACT_APP_API_BASE_URL;
export const REACT_APP_ONLINE_LESSON_TEACHER_URL = IS_DEV
  ? 'http://localhost:5001'
  : process.env.REACT_APP_ONLINE_LESSON_TEACHER_URL ?? '';
export const REACT_APP_COGNITO_REGION = process.env.REACT_APP_COGNITO_REGION;
export const REACT_APP_COGNITO_USER_POOL_ID =
  process.env.REACT_APP_COGNITO_USER_POOL_ID_FOR_TEACHER;
export const REACT_APP_COGNITO_USER_POOL_CLIENT_ID =
  process.env.REACT_APP_COGNITO_USER_POOL_CLIENT_ID_FOR_TEACHER;
export const REACT_APP_CHAT_WEBSOCKET_URL = process.env.REACT_APP_CHAT_WEBSOCKET_URL ?? "";

export const COOKIE_DOMAIN = IS_DEV
  ? 'localhost'
  : process.env.REACT_APP_TEACHER_URL!.match(/(?<=https:\/\/).*/)![0];

  export const COGNITO_PROVIDER = new CognitoIdentityProvider({
    region: REACT_APP_COGNITO_REGION,
  });

export const DELETE_FLG = {
  // 削除
  DELETED: '1',
  // 未削除
  AVAILABLE: '0',
} as const;
export type DELETE_FLG = typeof DELETE_FLG[keyof typeof DELETE_FLG];
export const STOP_FLG = {
  // 停止
  STOPPED: '1',
  // 未停止
  AVAILABLE: '0',
} as const;
export type STOP_FLG = typeof STOP_FLG[keyof typeof STOP_FLG];
export type QUERY_RESULT = number & { QUERY_RESULT: never };
export const MESSAGE = {
  NO_ERROR: 'NO_ERROR',
  INTERNAL_SERVER_ERROR: 'INTERNAL_SERVER_ERROR',
  NOT_AUTHORIZED_USER_ACCESS: 'NOT_AUTHORIZED_USER_ACCESS',
} as const;
export type MESSAGE = typeof MESSAGE[keyof typeof MESSAGE] | QUERY_RESULT;

export const LESSON_ENTRY_LOG_TYPE = {
  TEACHER_JOINED_LESSON: '1',
  LEARNER_JOINED_LESSON: '2',
} as const;

export const S3_PATH = 'https://sun-resource-tokyo.s3-ap-northeast-1.amazonaws.com/';
export const KURASHI_STUDY_API_PATH = 'https://api-manager.kurashi-study.net/cms/';

export const STORY_EPISODE_ID_SECTION_INDEX = {
  'Ep.x-1': 0,
  'Ep.x-2': 1,
  'Ep.x-3': 2,
  'Ep.x summary': 3,
} as const;

export const TOTAL_COUNT = {
  /** シーン（旧CHAPTER） */
  SCENE: 10,
  /** エピソードまとめ */
  EPISODE_SUMMARY: 12,
  /** シーズンまとめ */
  SEASON_SUMMARY: 50,
};

export const DEFAULT_GROUP_ID = 0;
export const MAX_VERIFY_CODE = 6;